// noinspection JSUnresolvedVariable

const appleLogin = () => {
    const remember = document.getElementById("RememberApple").checked;
    const autoAccessStr = remember ? "1" : "0";

    softLoad();

    localStorage.setItem("identibox_apple_autologin", autoAccessStr);
    AppleID.auth.signIn().then(response => {
        fetch(
            "/Pages/Login/Login.aspx/GetAppleCallbackUrl",
            {
                method: "POST",
                mode: "same-origin",
                cache: "no-cache",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    code: response.authorization.code,
                    idToken: response.authorization.id_token,
                    state: response.authorization.state,
                    autoAccess: remember
                })
            }).then(res => {
                stopLoading();
                res.json().then(obj => {
                    if (!obj.d) {
                        showError("Login non riuscito.");
                    } else {
                        window.location.replace(obj.d);
                    }
                });
            }
        ).catch(() => {
            stopLoading();
            showError("Login non riuscito.");
        });
    }).catch(() => {
        stopLoading();
        showError("Login non riuscito.");
    });
}

const initAppleAutoAccessCheckbox = () => {
    document.getElementById("RememberApple").checked =
        localStorage.getItem("identibox_apple_autologin") === "1"
}

if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", initAppleAutoAccessCheckbox);
} else {
    initAppleAutoAccessCheckbox();
}
